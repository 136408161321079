import styled from "styled-components";

export const RatingContainer = styled.div`
width: 100%;
height: 100%;
/* background: #000;
opacity: 0.6; */
position: absolute;
display: flex;
justify-content: center;
align-items: center;
`
export const RatingBackground = styled.div`
  width: 100%;
  height: 100%;
  background: #000;
  opacity: 0.6; /* Set the opacity for the background only */
  position: absolute;
  z-index: 99;
`;

export const RatingInnerBox = styled.div`
width: 604px;
height: 312px;
flex-shrink: 0;
border-radius: 20px;
background: #FFF;
z-index: 100;
padding: 40px 39px 49px 40px;
box-sizing: border-box;
position: relative;

.ratingText{
    color: #000;
leading-trim: both;
text-edge: cap;
font-family: Poppins;
font-size: 22px;
font-style: normal;
font-weight: 600;
line-height: normal;
/* background-color: red; */
}
.ratingDesc{
    color: #5B5B5B;
leading-trim: both;
text-edge: cap;
font-family: Poppins;
font-size: 20px;
font-style: normal;
font-weight: 400;
line-height: 36px; /* 180% */
margin-top: 12px;
}
`

export const RatingEmojiContainer=styled.div`
width: 100%;
height: 81px;
flex-shrink: 0;
/* background-color: gray; */
display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 30px;

`
export const EmojiBox= styled.div`
width: 97px;
height: 81px;
flex-shrink: 0;
border-radius: 6px;
background: #FFF1C9;
background-image: url(${props=>props.emojiIcon});
background-position: center;
background-repeat: no-repeat;
`
export const RatingCloseBtn= styled.div`
width: 30px;
height: 30px;
/* background: red; */
position: absolute;
top: 18px;
right: 24px;
display: flex;
justify-content: center;
align-items: center;
cursor: pointer;
`