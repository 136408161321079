import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { GlobalStyle } from "./styles/globalStyles";
import Main from "./components/Main/Main";
import Main2 from "./components/ExpiredMain/Main2";
import PublicSharedpage from "./components/PublicShared/PublicSharedPage";
import InvitationShare from "./components/PublicShared/InvitationShare";
 function App() {
  return (
    <div className="App">
      <GlobalStyle />
      <Router>
        <Routes>
          <Route path="/id/:platform/:foodyid" element={<Main2 />} />
          <Route path="/:userid/:platform/:foodyid" element={<Main />} />
          <Route path="/share/:platform/:userid" element={<PublicSharedpage />} />
          <Route path="/invitation/:username/:invitecode" element={<InvitationShare />} />
          {/* /share/zomato/23423546546778 */}
        </Routes>
      </Router>
    </div>
  );
}

export default App;
