import React from 'react'
import bannerImg from "../../pictures/sharePageBannerImg.png";
import useFooddyStore from '../../store';
import changIcon from "../..//pictures/change.svg";
import foodTray from "../../pictures/foodTray.svg";

import man1Icon from "../../assets/menEmoji/man1.png";
import man2Icon from "../../assets/menEmoji/man2.png";
import man3Icon from "../../assets/menEmoji/man3.png";
import man4Icon from "../../assets/menEmoji/man4.png";
import man5Icon from "../../assets/menEmoji/man5.png";
import man6Icon from "../../assets/menEmoji/man6.png";
import man7Icon from "../../assets/menEmoji/man7.png";
import man8Icon from "../../assets/menEmoji/man8.png";

import woman1Icon from "../../assets/womenEmoji/woman1.png";
import woman2Icon from "../../assets/womenEmoji/woman2.png";
import woman3Icon from "../../assets/womenEmoji/woman3.png";
import woman4Icon from "../../assets/womenEmoji/woman4.png";
import woman5Icon from "../../assets/womenEmoji/woman5.png";
import woman6Icon from "../../assets/womenEmoji/woman6.png";
import woman7Icon from "../../assets/womenEmoji/woman7.png";
import woman8Icon from "../../assets/womenEmoji/woman8.png";
import woman9Icon from "../../assets/womenEmoji/woman9.png";
import woman10Icon from "../../assets/womenEmoji/woman10.png";
import woman11Icon from "../../assets/womenEmoji/woman11.png";
import woman12Icon from "../../assets/womenEmoji/woman12.png";
import { GreetMessage, ProfilePic, PublicShareContImg, PublicShareContainer, SharePageBanner, SharePageDec, SharePageDetailDiv, SharepageCont } from './UserSharePage.styled';

function getRandomImg() {
    const imgArr = [
        man1Icon,
        man2Icon,
        man3Icon,
        man4Icon,
        man5Icon,
        man6Icon,
        man7Icon,
        man8Icon,
        woman1Icon,
        woman2Icon,
        woman3Icon,
        woman4Icon,
        woman5Icon,
        woman6Icon,
        woman7Icon,
        woman8Icon,
        woman9Icon,
        woman10Icon,
        woman11Icon,
        woman12Icon,
    ];
    const randomImg = imgArr[Math.floor(Math.random() * imgArr.length)];
    return randomImg;
}
function getRandomPercentage(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min + "%";
}

const getRandomColor = () => {
    const colors = ["#BEF0C6", "#FFC7D6", "#DBD1FC", "#FFD9A2"];
    return colors[Math.floor(Math.random() * colors.length)];
};

function UserInvitePage({ userName }) {
    return (
        <SharepageCont>
            <SharePageBanner className="sharepageBanner" bannerBg={bannerImg}>
                <ProfilePic prflPic={getRandomImg} bgColor={getRandomColor}>
                    <div className="SharePageDetailInnerDiv">
                    </div>
                </ProfilePic>
            </SharePageBanner>
            <SharePageDetailDiv>
                <GreetMessage>
                    Hey Buddy, I’m {userName} here!
                </GreetMessage>
                <SharePageDec>
                    Hey buddy, be a companion on spending. Let's compare!
                </SharePageDec>
                <PublicShareContainer
                    href="https://chrome.google.com/webstore/detail/spending-tracker-for-amaz/pmkoilojjepdgnbhnbfmbpjbcgfgbaoe"
                    target="_blank"
                >
                    <PublicShareContImg src={foodTray} />
                    Calculate my spending
                </PublicShareContainer>
            </SharePageDetailDiv>
        </SharepageCont>
    )
}

export default UserInvitePage
