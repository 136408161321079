import styled from "styled-components";


export const MainContainer=styled.div`
  position: absolute;
  height: 50px;
  width: 150px;
  display: flex;
  align-items: center;
  justify-content: start;
  left: 100px;
  z-index: 99;
`;
export const CardContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
    height: 50px;
  width: 200px; /* Adjust width as needed */
  position: relative;
`;

// Container for the profile image and orange background
export const ImageContainer = styled.div`
  position: relative;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  overflow: hidden;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ProfileImage = styled.img`
 position: absolute;
  width: auto;
  height: 100px;
  object-fit: cover;
  border-radius: 50%;
  z-index: 49;
  left: -25px;
    top: -15px;
`;

// Semi-circle orange background
export const OrangeBackground = styled.div`
  position: absolute;
  width: 40px;
  height: 40px;
  background-color: #F26724;
  clip-path: circle(50% at 50% 50%);
`;

// Container for the user info
export const UserInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 30px;
  width: 45%;
  position: absolute;
  background-color: white;
  z-index: -1;
  left: 30px;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
  padding-left: 5%;
`;

export const UserName = styled.div`
  font-size:15px;
  font-family: Poppines;
`;

export const UserAmount = styled.div`
  font-size: 8px;
  color: #ef7b45;
`;
