import {
    PubliSharedContainer,
    PublicShareInsideCont,
    PublicShareLeftContainer,
    PublicGoBackDiv,
    PublicGoBackImg,
} from "./PublicSharedPaje.styled";
import shareArrowIcon from "../../pictures/shareArrow.svg";
import shareArrowLightIcon from "../..//pictures/shareArrowLight.svg";

import backgroundImage from "../../pictures/background.png";
import { useState, useHistory } from "react";
import useFooddyStore from "../../store";
import { Link } from "react-router-dom";
import UserSharePage from "../UserSharePage/UserSharePage";
// import SkeletonLoader from "../SkeletonLoader/SkeletonLoader"
import FoodTray from "../../pictures/foodTray.svg";
import axios from "axios";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import UserInvitePage from "../UserSharePage/UserInvitePage";

function InvitationShare() {
    const params = useParams();
    useEffect(() => {
        document.cookie = `invitecode=${params.invitecode}; path=/; max-age=3600`
    }, [])
    return (
        <PubliSharedContainer backgroundImage={backgroundImage}>
            <PublicShareInsideCont>
                <PublicShareLeftContainer>
                    <PublicGoBackDiv>
                        <PublicGoBackImg src={FoodTray} />
                    </PublicGoBackDiv>
                </PublicShareLeftContainer>
                <UserInvitePage userName={params.username} />
            </PublicShareInsideCont>
        </PubliSharedContainer>
    )
}

export default InvitationShare
